import { motion } from "framer-motion";

const Section = (props:any):JSX.Element => {
    const {children, mobileTop} = props;
    return (
        <>
            <motion.section className={
                `h-screen w-screen p-8 max-w-screen-2xl mx-auto
                flex flex-col item-start justify-center
            ${mobileTop ? "justify-start md:justify-center": "justify-center"}
            `}
                initial={{
                    opacity: 0,
                    y: 50,
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                        duration: 1,
                        deray: 0.6,
                    }
                }}>

                {children}
            </motion.section>
        </>
    )
}

const TopSection = () => {
    return (
        <Section mobileTop>
            <h1 className="text-4xl md:text-8xl text-pink-300 font-extrabold leading-snug">
                LiFactory
            </h1>
            <motion.p className="text-4xl text-pink-400 mt-4"
            initial={{
                opacity: 0,
                y: 25,
            }}
            whileInView={{
                opacity: 1,
                y: 0,
            }}
            transition={{
                duration: 1,
                delay: 1.5,
            }}>
                make something on internet
            </motion.p>
            <motion.p className="text-lg text-red-600 mt-4"
            initial={{
                opacity: 0,
                y: 25,
            }}
            whileInView={{
                opacity: 1,
                y: 0,
            }}
            transition={{
                duration: 1,
                delay: 2,
            }}>
                Website under construction
            </motion.p>
        </Section>
    );
}

const AboutSection = () => {
    return (
        <Section mobileTop>
            <h1 className="text-2.5xl md:text-5xl text-white leading-snug">
                About Us
            </h1>
        </Section>
    );
}

const ProductSection = () => {
    return (
        <Section mobileTop>
            <h1 className="text-1xl md:text-1xl text-white leading-snug">
                product
            </h1>
        </Section>
    );
}

const WorldSection = () => {
    return (
        <Section mobileTop>
            <h1 className="text-1xl md:text-1xl text-white leading-snug">
                工事中
            </h1>
        </Section>
    );
}

export const Interface = () => {
    return(
        <div>
            <TopSection />
            <AboutSection />
            <ProductSection />
            <WorldSection />
        </div>
    )
}