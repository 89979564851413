import { Group } from "three";
import { Stars } from "@react-three/drei";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";

export const Particle = () => {
    const ref = useRef({} as Group);
    useFrame(() => {
        ref.current.rotation.z += 0.001;
        ref.current.rotation.x += 0.0001;
    });
    return (
        <group ref={ref}>
          <Stars
            radius={100} // 星の点滅(拡大)度合い
            depth={50}
            count={5000} // 星の数
            factor={10} // 星の大きさ
            saturation={9}
            speed={3} // 点滅のスピード
          />
        </group>
    );
}