
const MenuButton = (props:any) => {
    const {label, onClick} = props;
    return (
        <button onClick={onClick}
            className="text-2xl font-bold cursor-pointer hover:text-indigo-600 transition-colors"
        >
            {label}
        </button>
    );
};

export const Menu = (props:any) => {
    const { onSectionChange, menuOpened, setMenuOpened } = props;
    return(
        <>
            <button 
                onClick={() => setMenuOpened(!menuOpened)}
                className="z-20 fixed top-12 right-12 p-3 bg-gray-300 w-11 h-11 rounded-md">
                <div 
                    className={`bg-gray-600 h-0.5 rounded-md w-full transition-all ${
                        menuOpened ? "rotate-45 translate-y-0.5" : ""
                    }`}
                />
                <div 
                    className={`bg-gray-600 h-0.5 rounded-md w-full my-1 ${
                        menuOpened ? "hidden" : ""
                    }`}
                />
                <div 
                    className={`bg-gray-600 h-0.5 rounded-md w-full transition-all ${
                        menuOpened ? "-rotate-45" : ""
                    }`}
                />
            </button>
            <div 
                className={`z-10 fixed top-0 right-0 bottom-0 bg-gray-300 translate-all overflow-hidden flex flex-col
                ${menuOpened ? "w-full md:w-80" : "w-0"}`} 
            >
                <div className="flex-1 flex items-start justify-center flex-col gap-6 p-8">
                    <MenuButton label="Top" onClick={() => onSectionChange(0)} />
                    <MenuButton label="About" onClick={() => onSectionChange(1)} />
                    <MenuButton label="Product" onClick={() => onSectionChange(2)} />
                    <MenuButton label="????" onClick={() => onSectionChange(3)} />
                </div>
            </div>
        </>
    );
};