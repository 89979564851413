import { Sphere } from "@react-three/drei"
import { useFrame, useLoader } from '@react-three/fiber';
import { Group } from "three";
import { useRef } from "react";
import { TextureLoader } from 'three';

export const Planet = () => {
    const ref = useRef({} as Group);

    useFrame(() => {
        ref.current.rotation.y -= 0.001
        ref.current.rotation.z -= 0.0001
    });

    const texture = useLoader(TextureLoader,'./image/planet.png');
    return (
        <group ref={ref}>
        <Sphere args={[1,100,100]}>
          <meshStandardMaterial map={texture}></meshStandardMaterial>
        </Sphere>
        </group>
    )
}