import React, { useEffect, useState } from 'react';
import './App.css';
import './components/Header.css';
import { Canvas } from '@react-three/fiber';
import { Particle } from './components/Particle';
import { Planet } from './components/Planet';
import { Menu } from './components/Menu';
import { Scroll, ScrollControls } from '@react-three/drei';
import { Interface } from './components/Interface';
import { ScrollManager } from './components/ScrollManager';

function App() {
  const [section, setSection] = useState(0);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    setMenuOpened(false);
  }, [section]);

  return (
    <>
      <Canvas camera={{ fov: 55, near: 1, far: 20000 }} style={{ width: "100%", height: "100vh" }}>
        <color args={["#001"]} attach={"background"} />
        <ScrollControls pages={4} damping={0.1}>
          <ScrollManager section={section} onSectionChange={setSection} />
          <Particle />
          <Planet />
          <directionalLight position={[5,4,3]} />
          <Scroll html>
            <Interface />
          </Scroll>
        </ScrollControls>
      </Canvas>
      <Menu onSectionChange={setSection} menuOpened={menuOpened} setMenuOpened={setMenuOpened} />
    </>
  );
}

export default App;
